/* eslint-disable no-console,no-undef */
import React,{ Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAdminUser, createChallenge, getUserRole, getChallenges, createBehaviorLibrary, 
  getBehvaiorChallengeByIdAction, createActivityChallenge } from '../../redux/actions';
import {
  // EditChallenge,
  CreateChallenge,
  ChallengeDashboardV2,
  ChallengeDetailsV2,
  ChallengesCreation,
  Waiting,
  AdminRoute,
  NotFound,
  CompletedChallenge
} from '../../components';
import BehaviorChallenge from '../../components/BehaviorChallenge';
import { getPermissionStatus } from '../../utils/methods';
import { getCompany, setCompany } from '../../redux/actions/challengeActions';
import ChallengesCategory from '../../components/ChallengeCreationAdmin/index';
import ChallengesChallengeLibrary from '../../components/ChallengeCreationAdmin/ActivityChallengeByTypes';
import EditActivityChallenge from '../../components/ChallengeCreationAdmin/ActivityChallengeLibraryEdit/index';
import CreateActivityChallengeForm from '../../components/ChallengeCreationAdmin/ActivityChallengeCreation/index';
import BehaviorChallengeLaunch from '../../components/BehaviorChallenge/index';
import BehaviorChallengesTypes from '../../components/ChallengeCreationAdmin/behaviorChallengeByTypes';
import LaunchActivityChallenge from '../../components/ChallengeCreationAdmin/ActivityChallengeLaunch/index';
import EditChallenge from '../../components/ChallengeCreationAdmin/ActivityChallengeEdit';

class Challenges extends Component {

  componentDidMount() {
    const {userCompany, getAdminUser,fetchUserRole, fetchCompany, setCompany }= this.props;
    getAdminUser();
    fetchUserRole();
    // fetchChallenges();
    const startYear = userCompany.companyStartDate && userCompany.companyStartDate["company_start_date"];
    setCompany(userCompany.id, userCompany.name, startYear, userCompany.showBiometricStatus);
    fetchCompany();
  }

  challengeDashboardV2 = () =>{
    const { history, challengesData, userPermissions} = this.props;
    return(
      <ChallengeDashboardV2
        history={history}
        isAdmin={getPermissionStatus("Show Create Challenge Button", userPermissions)}
        challengesData={challengesData}
      />
    )
  }

  completeChallenges = () =>{
    const { history, companyInfo, selectedYearInfo, userPermissions} = this.props;
    const currentYear = new Date().getFullYear();
    let yearArray = [];
    for(let i = currentYear; i >= companyInfo["companyStartDate"]; i--){
      yearArray.push(i);
    }
    return(
      <CompletedChallenge
        history={history}
        yearList={yearArray}
        isAdmin={getPermissionStatus("Show Create Challenge Button", userPermissions)}
        selectedYearInfo={selectedYearInfo}
      />
    )
  }

  showChallengeCategories = () =>{
    const { history, role } = this.props;
    return (<ChallengesCategory history={history} role={role}/>)
  }

  challengesByChallengeTypes = () =>{
    const { history, role } = this.props;
    return (<ChallengesChallengeLibrary history={history} role={role}/>)
  }

  editActivityChallenge = () =>{
    const { history, role } = this.props;
    return (<EditActivityChallenge history={history} role={role}/>)
  }

  createActivityChallenge = () =>{
    const { history, role } = this.props;
    return (<CreateActivityChallengeForm history={history} createLibrary={true} role={role}/>)
  }

  behaviorByChallengeTypes = () =>{
    const { history, role } = this.props;
    return (<BehaviorChallengesTypes history={history} role={role}/>)
  }

  launchActivityChallenge = () =>{
    const { history, role } = this.props;
    return (<LaunchActivityChallenge history={history} role={role}/>)
  }

  editChallenge = () =>{
    const { history, role } = this.props;
    return (<EditChallenge history={history} role={role}/>)
  }

  render() {
    const { history, createChallenge, role, networkError, user, 
      createBehaviorLibrary, getBehvaiorChallengeByIdAction, behaviorChallengeDetailsById, createActivityChallenge, userPermissions} = this.props;
    if((  !role || !user ) && !networkError) {
      return <Waiting />
    }
    return (
      <Switch>
        {/* <AdminRoute
          path="/challenges/create-behavior-challenge/:id"
          role={role}
          createChallengeById={true}
          createChallenge={createChallenge}
          getBehvaiorChallengeByIdAction={getBehvaiorChallengeByIdAction}
          behaviorChallengeDetailsById={behaviorChallengeDetailsById}
          component={BehaviorChallenge}
          parentRoute="/challenges"
          editLibrary={false}
          allowedRoles={getPermissionStatus("Launch behavior library", userPermissions)}
        /> */}
        <AdminRoute
          path="/challenges/create-behavior"
          component={BehaviorChallenge}
          role={role}
          createLibrary={true}
          editLibrary={false}
          createBehaviorLibrary={createBehaviorLibrary}
          allowedRoles={getPermissionStatus("Create behavior library", userPermissions)}
          parentRoute="/challenges"
        />
        <AdminRoute
          path="/challenges/edit-behavior/:id"
          component={BehaviorChallenge}
          role={role}
          editLibrary={true}
          getBehvaiorChallengeByIdAction={getBehvaiorChallengeByIdAction}
          behaviorChallengeDetailsById={behaviorChallengeDetailsById}
          allowedRoles={getPermissionStatus("Update behavior library", userPermissions)}
          parentRoute="/challenges"
        />
        <AdminRoute
          path="/challenges/create"
          component={CreateChallenge}
          createChallenge={createChallenge}
          createActivityChallenge={createActivityChallenge}
          createLibrary={true}
          history={history}
          parentRoute="/challenges"
          allowedRoles={getPermissionStatus("Create activity library", userPermissions)}
        />       
        <AdminRoute
          path="/challenges/show-all"
          component={ChallengesCreation}
          createChallenge={createChallenge}
          history={history}
          parentRoute="/challenges/show-all"
          allowedRoles={getPermissionStatus("Show Create Challenge Button", userPermissions)}
          user={user}
        />
        {/* <AdminRoute path="/challenges/:id/edit" 
          component={EditChallenge} 
          createChallenge={createChallenge}
          history={history}
          parentRoute="/challenges"
          allowedRoles={getPermissionStatus("Update launched behavior library", userPermissions) || getPermissionStatus("Update launched activity challenge", userPermissions)}
        /> */}
        <Route path="/challenges/edit/:id" render={this.editChallenge} exact={true}/>
        <AdminRoute
          path="/challenges/create-behavior-challenge/:id"
          role={role}
          createChallengeById={true}
          createChallenge={createChallenge} 
          getBehvaiorChallengeByIdAction={getBehvaiorChallengeByIdAction} 
          behaviorChallengeDetailsById={behaviorChallengeDetailsById} 
          component={BehaviorChallengeLaunch} 
          parentRoute="/challenges" 
          editLibrary={false}  
          allowedRoles={getPermissionStatus("Launch behavior library", userPermissions)}
        />
        <AdminRoute
          path="/challenges/edit-behavior/:id"
          role={role}
          component={BehaviorChallengeLaunch}
          editLibrary={true}
          getBehvaiorChallengeByIdAction={getBehvaiorChallengeByIdAction}
          behaviorChallengeDetailsById={behaviorChallengeDetailsById}
          allowedRoles={getPermissionStatus("Update behavior library", userPermissions)}
          parentRoute="/challenges"
        />
        <Route path="/challenges/activity-launch/:id" render={this.launchActivityChallenge} exact={true}/>
        <Route path="/challenges/behavior-categories/:type" render={this.behaviorByChallengeTypes} exact={true}/>
        <Route 
          path="/challenges/categories/:type" 
          render={this.challengesByChallengeTypes}
        />
        <Route 
          path="/challenges/categories" 
          render={this.showChallengeCategories}
        />
        <Route path="/challenges/activity-edit/:id" render={this.editActivityChallenge} exact={true}/>
        <Route path="/challenges/create-activty" render={this.createActivityChallenge} exact={true}/>
        <Route path="/challenges/completed" 
          render={this.completeChallenges}
        />
        <Route path="/challenges/:id" component={ChallengeDetailsV2} />
        <Route
          render={this.challengeDashboardV2}
        />
        <Route component={NotFound}/>
      </Switch>
    )
  }
}

Challenges.defaultProps = {
  companyInfo: {id: 1, name: '', companyStartDate: 2016}
};

Challenges.propTypes = {
  history: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  getAdminUser: PropTypes.func.isRequired,
  createChallenge: PropTypes.func.isRequired,
  fetchUserRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  fetchChallenges: PropTypes.func.isRequired,
  challengesData: PropTypes.array,
  networkError: PropTypes.string,
  user: PropTypes.object,
  createBehaviorLibrary: PropTypes.func,
  getBehvaiorChallengeByIdAction: PropTypes.func,
  behaviorChallengeDetailsById: PropTypes.object,
  createActivityChallenge: PropTypes.func,
  userPermissions: PropTypes.array,
  fetchCompany: PropTypes.func.isRequired,
  companyInfo: PropTypes.object,
  userCompany: PropTypes.object.isRequired,
  setCompany: PropTypes.func.isRequired,
  selectedYearInfo: PropTypes.number
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  role: state.auth.role,
  challengesData: state.challenges.response,
  networkError: state.challenges.networkError,
  user: state.profileData.user,
  behaviorChallengeDetailsById: state.challenges.behaviorChallengeDetaillsById,
  userPermissions: state.profileData.userPermissions,
  companyInfo: state.challenges.companyInfo,
  selectedYearInfo: state.companyDashboard.selectedYearInfo
});

const mapDispatchToProps = (dispatch) => ({
  getAdminUser: () => dispatch(getAdminUser()),
  createChallenge: (data, onHide, path) => dispatch(createChallenge(data, onHide,path)),
  createBehaviorLibrary: (data, onHide, path) => dispatch(createBehaviorLibrary(data, onHide, path)),
  fetchUserRole: () => dispatch(getUserRole()),
  fetchChallenges: () => dispatch(getChallenges()),
  getBehvaiorChallengeByIdAction: (id) => dispatch(getBehvaiorChallengeByIdAction(id)),
  createActivityChallenge: (challenge,path) => dispatch(createActivityChallenge(challenge,path)),
  fetchCompany: () => dispatch(getCompany()),
  setCompany: (id, name, companyStartDate, biometricStatus) => dispatch(setCompany(id, name, companyStartDate, biometricStatus))
});

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);
