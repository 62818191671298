import React , {Component} from 'react';
import {checkPermission} from "../../utils/methods";
import {CommunityRecognitionPage, LandingFooterPanelV3, Layout, NavigationBarV2} from "../../components";
import PropTypes from "prop-types";
class CommunityRecognition extends Component{
  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <CommunityRecognitionPage  history={history}/>
        <LandingFooterPanelV3 isUser={authToken} />
      </Layout>
    );
  }
}

CommunityRecognition.propTypes = {
  history: PropTypes.object
}

export default CommunityRecognition;