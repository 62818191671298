/* eslint-disable no-undef */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Layout } from '../../components';

const Button = styled.button`
  background-color: #56d4dd;
  box-shadow: 0px 25px 73px #B3D7F1;
  padding: 20px 30px;
  font-size: 18px;
  margin-top: 30px;
  border-radius: 30px;
  border: none;
  &:focus, &:active {
    outline: none;
  }
`;

const Wrapper = styled.div`
  float: left;
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 1000px; 
`;

class ComingSoon extends PureComponent {
  componentDidMount() {
    window.scrollTo(0,0);
  }

  redirection = () =>{
    const { history } = this.props;
    history.push("/")
  }

  render() {
    return (
      <Layout>
        <Wrapper>
          <h1>The page you are looking for is currently under development</h1>
          <Button onClick={()=>this.redirection()}>Click here for home</Button>
        </Wrapper>
      </Layout>
    )
  }
}

ComingSoon.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ComingSoon);